import React from "react"

import tronBank from '../../images/buyTrx/tron-bank.png'
import tronBull from '../../images/buyTrx/tron-bull.png'
import blockchain from '../../images/buyTrx/blockchain.png'
//https://www.binancecnt.com/en/pre-connect?merchantCode=tron&cryptoCurrency=TRX&timestamp=${new Date().getTime()}
const BuyTrx = () => {
  return(
    <div className="buy-trx-wrap">
      <div className="buy-trx-content available-wrap">
        <div className="buy-trx-info">
          <img className="buy-trx-logo" src={blockchain} alt="" />
          <p>
            Buy TRX through<br />
            Blockchain.com
          </p>
          <a target="_blank" href={`https://www.blockchain.com/pay/widget?apiKey=2d5a9c8d-2d27-4de9-84a6-95b3a05b3610&timestamp=${new Date().getTime()}`}>Buy TRX</a>
        </div>
        <div className="buy-trx-bank">
          <img className="tron-bank" src={tronBank} alt="" />
          <img className="tron-bull" src={tronBull} alt="" />
        </div>
      </div>
    </div>
  )
}

export default BuyTrx